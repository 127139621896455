exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artists-cosmicjs-artists-slug-js": () => import("./../../../src/pages/artists/{CosmicjsArtists.slug}.js" /* webpackChunkName: "component---src-pages-artists-cosmicjs-artists-slug-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-bass-cosmicjs-additions-slug-js": () => import("./../../../src/pages/bass/{CosmicjsAdditions.slug}.js" /* webpackChunkName: "component---src-pages-bass-cosmicjs-additions-slug-js" */),
  "component---src-pages-bass-cosmicjs-basses-slug-js": () => import("./../../../src/pages/bass/{CosmicjsBasses.slug}.js" /* webpackChunkName: "component---src-pages-bass-cosmicjs-basses-slug-js" */),
  "component---src-pages-cello-cosmicjs-cellos-slug-js": () => import("./../../../src/pages/cello/{CosmicjsCellos.slug}.js" /* webpackChunkName: "component---src-pages-cello-cosmicjs-cellos-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cosmicjs-basic-pages-slug-js": () => import("./../../../src/pages/{CosmicjsBasicPages.slug}.js" /* webpackChunkName: "component---src-pages-cosmicjs-basic-pages-slug-js" */),
  "component---src-pages-cosmicjs-bows-metadata-product-category-key-bows-cosmicjs-bows-slug-js": () => import("./../../../src/pages/{CosmicjsBows.metadata__product_category__key}/bows/{CosmicjsBows.slug}.js" /* webpackChunkName: "component---src-pages-cosmicjs-bows-metadata-product-category-key-bows-cosmicjs-bows-slug-js" */),
  "component---src-pages-cosmicjs-cases-metadata-product-category-key-cases-cosmicjs-cases-slug-js": () => import("./../../../src/pages/{CosmicjsCases.metadata__product_category__key}/cases/{CosmicjsCases.slug}.js" /* webpackChunkName: "component---src-pages-cosmicjs-cases-metadata-product-category-key-cases-cosmicjs-cases-slug-js" */),
  "component---src-pages-dealers-js": () => import("./../../../src/pages/dealers.js" /* webpackChunkName: "component---src-pages-dealers-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-viola-cosmicjs-violas-slug-js": () => import("./../../../src/pages/viola/{CosmicjsViolas.slug}.js" /* webpackChunkName: "component---src-pages-viola-cosmicjs-violas-slug-js" */),
  "component---src-pages-violin-cosmicjs-violins-slug-js": () => import("./../../../src/pages/violin/{CosmicjsViolins.slug}.js" /* webpackChunkName: "component---src-pages-violin-cosmicjs-violins-slug-js" */),
  "component---src-templates-sectioned-page-js": () => import("./../../../src/templates/sectioned-page.js" /* webpackChunkName: "component---src-templates-sectioned-page-js" */)
}

