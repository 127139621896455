import React, { createContext, useMemo, useState } from 'react'

// hooks
import useAllDisplayRegions from '../hooks/use-all-display-regions'

export const UserContext = createContext()

const ipDataApiKey = process.env.GATSBY_IPDATA_API_KEY
const ipDataUrl = `https://api.ipdata.co/?api-key=${ipDataApiKey}&fields=country_code`

const fetchData = async (regionsToCheck) => {
  const response = await fetch(ipDataUrl)
  const data = await response.json()

  for (const regionToCheck of regionsToCheck) {
    const countryCodes = regionToCheck.metadata?.country_codes?.split('\n')

    if (data && countryCodes?.includes(data.country_code)) {
      console.debug(`region match, set to ${regionToCheck.slug}`)
      return regionToCheck.slug
    }
  }

  console.debug(`no region match, set to global`)
  return 'global'
}

const UserProvider = ({ children }) => {
  const [customerRegion, setCustomerRegion] = useState()
  const [madeApiCall, setMadeApiCall] = useState(false)
  const displayRegions = useAllDisplayRegions()
  const regionsToCheck = displayRegions.filter(
    (displayRegion) => displayRegion.slug !== 'global',
  )

  // using useMemo instead of useEffect so it gets called before render
  useMemo(async () => {
    // only fetch ipdata if customer region has not been set and an api call is not already in progress
    if (typeof window !== undefined && !customerRegion && !madeApiCall) {
      console.debug(`get region from ipdata`)
      setMadeApiCall(true)
      const region = await fetchData(regionsToCheck)
      setCustomerRegion(region)
    }
  }, [customerRegion, madeApiCall, regionsToCheck])

  return (
    <UserContext.Provider value={{ customerRegion }}>
      {children}
    </UserContext.Provider>
  )
}

/* eslint-disable import/no-anonymous-default-export */
export default ({ element }) => <UserProvider>{element}</UserProvider>
/* eslint-enable import/no-anonymous-default-export */
