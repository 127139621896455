/*
  HOOK: useAllDisplayRegions
  PURPOSE: A hook to run a static query to retrieve all Display Regions
*/
import { useStaticQuery, graphql } from 'gatsby'

const useAllDisplayRegions = () => {
  const { allCosmicjsDisplayRegions } = useStaticQuery(
    graphql`
      query ALL_DISPLAY_REGIONS {
        allCosmicjsDisplayRegions {
          nodes {
            id
            slug
            title
            metadata {
              country_codes
            }
          }
        }
      }
    `,
  )

  return allCosmicjsDisplayRegions.nodes
}

export default useAllDisplayRegions
